a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

fieldset:not(.MuiOutlinedInput-notchedOutline) {
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  min-width: 0 !important;
}

html {
  -webkit-tap-highlight-color: transparent; /* for removing the highlight */
}

body {
  font-family: "Nunito", Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
  line-height: 1.42857143;
  color: #333333;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
